import React, { useEffect } from "react";
import { IndexPageContainer } from ".";
import Cookies from "cookies-js";
import SEO from "@src/components/SEO";

export default function IndexPage({ pageContext }) {
  return (
    <>
      <SEO noindex={true} />
      <IndexPageContainer pageContext={pageContext} inHomepageExperiment="control1" />
    </>
  );
}
